html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #ddd;
  color: #111;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-top: 4px solid green;

  &.auth {
    border-color: blue;
  }

  &.app {
    border-color: orange;
  }
}

a {
  padding: 0.5rem;
  color: blue;
}

a.active {
  color: green;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;

    svg {
      height: 3rem;
      width: 3rem;
    }
  }
}

main {
  flex-grow: 1;
}

footer {
  text-align: center;
}
